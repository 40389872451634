import styled from 'styled-components';

const HeaderWrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9;

    @media (max-width: 768px) {
    display: none;
  }

  ul {
    font-family: "Source Sans Pro", sans-serif;
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    justify-content: center;
    li {
      margin: 0;

      a {
        cursor: pointer;
        display: block;
        padding: .5em 1em;
        text-transform: uppercase;
        
        &.active {
          color: red;
        }
      }
    }
  }
`;

export default HeaderWrapper;