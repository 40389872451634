import styled from 'styled-components';

const MobileNavWrapper = styled.div`
  .button {
    width: 100px;
    height: 100px;
    position: fixed;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #48a776;
    border-radius: 50%;
    z-index: 9;
  }

  ul {
    padding: 15px 0 40px 0;
    margin: 0;
    list-style: none;
    background-color: #48a776;
    color: #fff;
    position: fixed;
    bottom: -100%;
    width: 100%;
    text-align: center;
    transition: .25s ease-in-out;
    
    li {
      margin-bottom: 5px;
    }
  }

  .dash {
    display: block;
    position: absolute;
    height: 3px;
    width: 35px;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 32px;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 12px;
    }

    &:nth-child(2),&:nth-child(3) {
      top: 20px;
    }

    &:nth-child(4) {
      top: 28px;
    }
  }

  &.open {
    ul {
      bottom: 0;
    }
  }

  &.open .dash:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  &.open .dash:nth-child(2) {
    transform: rotate(45deg);
  }

  &.open .dash:nth-child(3) {
    transform: rotate(-45deg);
  }

  &.open .dash:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

    @media (min-width: 756px) {
    display: none;
  }
`;

export default MobileNavWrapper;