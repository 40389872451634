import React from 'react';

const FbShareLikeButton = () => (
  <div style={{ marginTop: '1em' }}>
    <div 
      className="fb-like" 
      data-href="https://dogorynogami.imielscywpodrozy.pl/" 
      data-width="" 
      data-layout="button_count" 
      data-action="like" 
      data-size="small" 
      data-share="true"
    ></div>
  </div>
);

export default FbShareLikeButton;