import React, { useState } from 'react';
import { Events } from 'react-scroll';

import MobileNavWrapper from './styles/MobileNavStyles';

import Menu from './shared/Menu';

const MobileNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOverlayMenu = () => {
    setMenuOpen(!menuOpen);
  }

  Events.scrollEvent.register('end', function(to, element) {
    setMenuOpen(false);
  });

  return (
    <MobileNavWrapper 
      className={`${menuOpen ? "open" : ""}`}
      onClick={handleOverlayMenu}
    >
      <div className="button">
        <span className="dash"></span>
        <span className="dash"></span>
        <span className="dash"></span>
        <span className="dash"></span>
      </div>
      
      <Menu isMobile={true} />
    </MobileNavWrapper>
  );
};

export default MobileNav;