import styled from 'styled-components';

import grass from '../../images/grass4.svg';

const FooterWrapper = styled.footer`
  background-image: url(${grass});
  background-repeat: repeat-x;
  background-position: center 140%;
  height: 150px;
`;

export default FooterWrapper;