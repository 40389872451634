/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import AboutBook from "../components/AboutBook"
import Header from "./header"
import MobileNav from './MobileNav';

import "./layout.css"
import FooterWrapper from './styles/FooterStyles';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <AboutBook />
        <main
          style={{
            margin: 'auto',
            maxWidth: 680,
            padding: `0 1em`,
          }}
        >{children}</main>
        <FooterWrapper>
          <MobileNav/>
        </FooterWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
