import React from "react";
import { Link } from "react-scroll";

const menuItems = [
  {text: 'O książce', linkTo: 'about-book'},
  {text: 'Fragment', linkTo: 'book-fragment'},
  {text: 'O nas', linkTo: 'about-us'},
  {text: 'Opinie', linkTo: 'opinions'},
  {text: 'Pobierz książkę', linkTo: 'download-book'}
];

const MenuItem = ({text, linkTo, isMobile}) => (
  <li>
    <Link
      to={linkTo}
      smooth={true}
      offset={isMobile ? -25 : -60}
      duration={500}
    >{text}</Link>
  </li>
);

const Menu = ({ isMobile }) => (
  <ul>
     {menuItems.map((menuItem, idx) => (
       <MenuItem key={idx} isMobile={isMobile} {...menuItem} />
     ))}
   </ul>
);

export default Menu;