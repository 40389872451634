import React from "react"
import HeaderWrapper from './styles/HeaderStyles';

import Menu from './shared/Menu';

const Header = () => (
  <HeaderWrapper>
    <Menu />
  </HeaderWrapper>
)

export default Header;
