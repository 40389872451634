import styled from 'styled-components';

const AboutBookWrapper = styled.div`
  #about-book {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 960px;
    min-height: 100vh;
    margin: auto;
    padding: 0 1em;

    .image {
      width: 350px;
      
      img { 
        margin: 0;
      }
    }

    p {
      margin-bottom: 0;
    }

    .text {
      max-width: 500px;
    }

        @media (max-width: 756px) {
      flex-direction: column-reverse;
      align-items: center;
      padding: 2em 1em 0 1em;
      margin-bottom: 50px;

      .image {
        margin-bottom: 2em;
      }
    }
  }
`;

export default AboutBookWrapper;