import React from 'react';

import AboutBookWrapper from './styles/AboutBookStyles';

import heroImage from '../images/about-book.png';
import FbShareLikeButton from './FbShareLikeButton';

const AboutBook = () => (
  <AboutBookWrapper>
    <div id="about-book">
      <div className="text">
        <h1>Do góry nogami</h1>
        <p>
          Historia o koniu Kacprze, bo tak nazywa się główny bohater bajki „Do góry nogami”, początkowo miała być opowieścią o słoniu Sławku. Jednak wymyślając postać słonika mieszkającego w odległej Azji, zawędrowałam myślami na południe Polski, gdzie od wielu lat mówi się o ciężkich warunkach w jakich pracują konie na górskich szlakach. Moje podejście w tym temacie nie jest zero jedynkowe. Nie chciałabym odebrać szansy na zobaczenie gór osobom, które ze względu na stan zdrowia czy wiek, nie są w stanie zdobyć ich o własnych siłach. W rzeczywistości jednak, dobrze wiemy, że takich turystów policzyć można na palcach jednej ręki. Ludzie ochoczo korzystają z transportu konnego, bo mają taką możliwość, a konie ciężko pracują, bo jest na to zapotrzebowanie. I tak zatacza się błędne koło. 
        </p>
        <FbShareLikeButton />
      </div>
      <div className="image">
        <img src={heroImage} alt="KAcper i Tosia" />
      </div>
    </div>
  </AboutBookWrapper>
)

export default AboutBook;
